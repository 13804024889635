import { Content } from "antd/lib/layout/layout";
import React from "react";

import { LayoutGeneral } from "src/layout/LayoutGeneral";

import "src/components/products/style.scss";
import "src/components/products/thermoActive/style.scss";
import ThermoPlusFichaTecnica from "src/components/products/thermoActive/FichaTecnica";

const ThermoActive = (props) => (
  <LayoutGeneral
    {...{ ...props, productName: "ThermoActive", showPageTitle: false }}
  >
    <Content className="product thermoActive fichaTecnica">
      <ThermoPlusFichaTecnica />
    </Content>
  </LayoutGeneral>
);

export default ThermoActive;
