import React from "react";

import { Divider, List } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import { Link } from "gatsby";

export const ThermoPlusFichaTecnica = () => {
  const data = [
    {
      content: (
        <>
          <b className="paragraphTitle">Composición</b>
          Dispersión de poliacrilato, óxido de zinc, dióxido de titanio, caolín,
          carbonato de calcio, silicatos, agua, propilenglicol, celulosa,
          conservantes
          <b className="paragraphTitle">Grupo de productos/Código SIG</b>
          BSW20
          <br />
          Es de aplicación la información contenida en la ficha de datos de
          seguridad vigente.
          <b className="paragraphTitle">Áreas de aplicación</b>
          ClimateCoating ThermoActive es un revestimiento protector resistente
          adecuado para su uso en casi todas las superficies de cubiertas de
          edificios. La pendiente de la cubierta no debe ser inferior al 3%. La
          especial composición de ClimateCoating ThermoActive proporciona una
          amplia gama de áreas de aplicación energética para generar un escudo
          térmico en verano.
          <b className="paragraphTitle">Propiedades específicas del producto</b>
          Permeabilidad variable al vapor de agua, alta resistencia a
          influencias ambientales agresivas como la niebla tóxica (smog) y el
          ozono troposférico, bajas emisiones, libre de disolventes, alta
          resistencia a los rayos UV y a los agentes climatológicos, reflectante
          a la radiación solar, muy alta resistencia del color, impermeable,
          capacidad de elongación, genera condiciones de confort térmico.
          <b className="paragraphTitle">Datos del material</b>
          <ul>
            <li>
              Valores sd según DIN 52615 zona seca: sd = 0,58 zona húmeda: sd =
              0,42
            </li>
            <li>Densidad: 1,04 kg/dm3 ISO 2811-1</li>
            <li>Comportamiento frente al fuego: C-s1, d0</li>
            <li>Resistencia química: disponible bajo consulta</li>
            <li>Reflectancia solar: 84% según DIN 67507</li>
            <li>Brillo: brillo mate según DIN ISO 2813</li>
          </ul>
          <b className="paragraphTitle">Reglamento de COV (CE)</b>
          Valor límite de 01.01.2010 para material de
          revestimiento(Prod.Cat.d)en base de agua (Wb) para materiales de
          madera, metal o polímeros para edificios, componentes o elementos
          decorativos = 130 g/l. Este producto contiene {"< 7"} g/l VOC.
          Superficies de aplicación ClimateCoating ThermoActive se puede aplicar
          sobre todas las superficies sólidas, limpias, secas, libres de óxido y
          libres de grasa, como metales, materiales sintéticos, fibras
          sintéticas y superficies firmes previamente pintadas. Se recomienda
          una superficie permeable para garantizar las propiedades energéticas.
        </>
      ),
    },
    {
      content: (
        <>
          <b className="paragraphTitle">Preparación del soporte</b>
          <ul>
            <li>Materiales sintéticos: analizar la capacidad de adherencia</li>
            <li>
              Metales: dependiendo del metal y la tensión, imprimar con
              RustPrimer o ZincPrimer
            </li>
            <li>
              Revestimiento anterior no fiable o adecuado: dependiendo del
              revestimiento, eliminar por lavado, limpieza ácida, limpiador de
              humedad caliente de alta presión
            </li>
            <li>Superficies que interfieren en la adherencia: lavar, lijar</li>
            <li>Sustratos absorbentes: aplicar imprimación FixPlus</li>
          </ul>
          <b className="paragraphTitle">Aplicación</b>
          El producto se puede aplicar mediante brocha, rodillo y pulverización
          con airless. Para la primera aplicación se requieren dos capas.
          ClimateCoating ThermoActive se suministra listo para su uso. En caso
          de aplicación a través de equipos de pulverización, se deben seguir
          las instrucciones del fabricante del equipo. Para su aplicación en
          superficies muy rugosas o mediante el uso de equipos de pulverización,
          la consistencia del producto se puede regular añadiendo una pequeña
          cantidad de agua. Durante la aplicación, es importante mantener la
          temperatura del aire y del objeto a un mínimo de 5°C. Esto se aplica
          igualmente al período de secado. Una suficiente luz diurna promueve el
          secado del ligante reticulado UV que contiene el producto. Remueva con
          un aparato mezclador antes de la aplicación o después de largas
          interrupciones del trabajo. Permita un período de secado de 12 horas
          entre dos capas sucesivas.
          <b className="paragraphTitle">Consumo</b>
          El rendimiento de ClimateCoating ThermoActive es de aproximadamente
          600 ml/m2, calculado para dos capas, sobre superficies lisas de baja
          porosidad. Utilizando material embebido (ej: fieltro geotextil), la
          capacidad de esparcimiento se eleva a aproximadamente 1000 ml/m2,
          calculado para dos capas. La capacidad de esparcimiento variará según
          la estructura y la porosidad de la superficie. En caso de duda,
          primero se debe aplicar sobre un área de prueba pequeña. Para
          optimizar la eficiencia energética del escudo térmico en verano, puede
          ser necesaria una capa de revestimiento más gruesa, dependiendo del
          clima y la superficie.
        </>
      ),
    },
    {
      content: (
        <>
          <b className="paragraphTitle">Limpieza de herramientas</b>
          Los rodillos, brochas y equipos de pulverización deben lavarse con
          agua a fondo inmediatamente después de su uso.
          <b className="paragraphTitle">Volumen de suministro</b>
          12,5 litros Diferentes tamaños de envase disponibles bajo petición.
          <b className="paragraphTitle">Colores</b>
          ClimateCoating ThermoActive se puede tintar según los sistemas de
          color NCS, RAL y ClimateCoating Edition 1. Por favor, compruebe la
          tonalidad del color antes de la aplicación.
          <b className="paragraphTitle">Almacenamiento y transporte</b>
          ClimateCoating ThermoActive debe almacenarse y transportarse en
          ambiente frío, pero protegido contra heladas. Una vez abiertos, los
          recipientes deben ser cerrados firmemente, y aplicado el producto lo
          antes posible. Almacenable a partir de la fecha de fabricación durante
          12 meses.
          <b className="paragraphTitle">Gestión de residuos</b>
          No vacíe los restos de producto en la red de evacuación, en cursos de
          agua o el terreno. Sólo los recipientes completamente vaciados deben
          ser desechados para su reciclaje. Por favor, deposite los productos
          parcialmente utilizados en un punto de gestión de residuos autorizado.
          Código de residuos no peligrosos según la lista de residuos de la UE:
          080120
          <b className="paragraphTitle">Aviso de seguridad</b>
          El contenido de la ficha técnica no manifiesta ninguna relación
          jurídica contractual. En cualquier caso, el aplicador/cliente tiene
          que considerar si el material es utilizable en condiciones prácticas
          antes de la aplicación. No permitir la entrada en la red de
          evacuación, en cursos de agua o el terreno. Mantener alejado de
          alimentos, bebidas y piensos. Mantener fuera del alcance de los niños.
          Proteger las superficies que no deben tratarse tomando las medidas
          adecuadas. Evitar el contacto con la piel y los ojos. Cuando se
          aplique con el método de pulverización, evitar la inhalación de polvo
          emergente. La información anteriormente mencionada dejará de ser
          válida al publicarse una nueva ficha técnica consecuencia de la mejora
          del producto. La información proporcionada fue verificada por
          ClimateCoating GmbH en laboratorio y en la práctica como valores de
          referencia. La información del producto se ofrece en base a nuestro
          mejor conocimiento y de acuerdo con el estado actual de la tecnología.
          Influencias ambientales, materiales, equipos de aplicación y métodos
          de aplicación están fuera de nuestro control y, por lo tanto, fuera de
          nuestra responsabilidad. ClimateCoating©2019
        </>
      ),
    },
  ];

  return (
    <>
      <div className="linkBack">
        <Link to="/productos/thermoactive/">
          <RollbackOutlined />{" "}
          <span className="colored linkText">Ir a ThermoActive</span>
        </Link>
      </div>{" "}
      <div className="productDocHeader">
        <h1 className="centered">Ficha Técnica ThermoActive</h1>
      </div>
      <Divider />
      <div style={{ marginBottom: "50px" }}>
        <List
          grid={{ gutter: 24, column: 3 }}
          dataSource={data}
          renderItem={({ content }) => <List.Item>{content}</List.Item>}
        />
      </div>
    </>
  );
};

export default ThermoPlusFichaTecnica;
